import { useState, useCallback, useEffect } from "react";
import { createTheme } from "@mui/material/styles";
import { trTR, enUS } from "@mui/x-data-grid";
import { trTR as coretrTR, enUS as coreenUS } from "@mui/material/locale";
import { useHttpClient } from "shared/hooks/http-hook";
// import { redirect } from "react-router-dom";

let logoutTimer;

export const useAuth = () => {
  const [token, setToken] = useState(false);
  const [tokenExpirationDate, setTokenExpirationDate] = useState();
  const [userId, setUserId] = useState(false);
  const [userName, setUserName] = useState("");
  const [school, setSchool] = useState();
  const [userType, setUserType] = useState();
  const [company, setCompany] = useState();
  const [schoolType, setSchoolType] = useState();
  const [userLoaded, setUserLoaded] = useState(false);

  const [theme, setTheme] = useState();

  const { sendRequest } = useHttpClient();

  const getSchoolType = useCallback(
    async (uid, mytoken) => {
      if (uid) {
        try {
          const responseDataUser = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/users/${uid}`, "GET", null, {
            "Content-Type": "application/json",
            Authorization: "Bearer " + mytoken,
          });

          setUserName(responseDataUser.user.name);
          const currentSchoolId = responseDataUser.user.school[0].toString();
          setSchool(currentSchoolId);

          const currentUserType = responseDataUser.user.usertype.toString();
          setUserType(currentUserType);
          const responseDataSchool = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/schools/${currentSchoolId}`
          );

          if (responseDataUser?.user?.company) {
            const currentCompanyId = responseDataUser?.user?.company.toString();
            setCompany(currentCompanyId);
          } else {
            if (responseDataSchool?.school?.company) {
              setCompany(responseDataSchool.school.company.toString());
            } else {
              setCompany("619c64805d123200169418bf");
            }
          }

          return responseDataSchool.school.type;
        } catch (err) {
          console.log(err);
        }
      }
    },
    [sendRequest]
  );

  const UpdateTheme = useCallback((theme) => {
    setTheme(
      createTheme(
        {
          palette: {
            primary: { main: "#1976d2" },
          },
        },
        theme === "tr" ? trTR : enUS,
        theme === "tr" ? coretrTR : coreenUS,
        {
          GridCsvExportOptions: { fileName: "gh" },
        }
      )
    );
  }, []);
  const login = useCallback(
    async (uid, token, expirationDate, userName) => {
      const currentSchoolType = await getSchoolType(uid, token);
      const tokenExpirationDate = expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365);

      localStorage.setItem(
        "userData",
        JSON.stringify({
          userId: uid,
          token: token,
          userName: userName,
          schoolType: currentSchoolType,
          expiration: tokenExpirationDate.toISOString(),
        })
      );

      setToken(token);
      setUserId(uid);
      setUserName(userName);

      setTokenExpirationDate(tokenExpirationDate);

      setSchoolType(currentSchoolType);
      setUserLoaded(true);
    },
    [getSchoolType]
  );

  const logout = useCallback(() => {
    setToken(null);
    setTokenExpirationDate(null);
    setUserId(null);
    setUserName(null);
    localStorage.removeItem("userData");
    // redirect("/login");
  }, []);

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime = tokenExpirationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpirationDate]);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userData"));
    if (storedData && storedData.token && new Date(storedData.expiration) > new Date()) {
      login(storedData.userId, storedData.token, new Date(storedData.expiration), storedData.userName);
    } else {
      setUserLoaded(true);
    }
  }, [login]);

  return {
    token,
    userLoaded,
    login,
    logout,
    userId,
    userName,
    school,
    company,
    userType,
    schoolType,
    theme,
    UpdateTheme,
  };
};
