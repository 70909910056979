import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import GoogleMapReact from "google-map-react";
import RoomIcon from "@mui/icons-material/Room";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

// #region Components
import Input from "shared/components/FormElements/Input";
import CustomSelect from "shared/components/FormElements/Select";
import Button from "shared/components/FormElements/Button";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import Marker from "shared/components/UIElements/Marker";
// #endregion Components

// #region Utils
import { generatePhoneNumber } from "shared/util/util";
import { VALIDATOR_REQUIRE, VALIDATOR_PHONENUMBER, VALIDATOR_MAX, VALIDATOR_MIN } from "shared/util/validators";
import { LocationFromAddress } from "shared/util/location";
import { schooltypes, qrcodetypes } from "shared/util/types";
// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

const NewSchoolItem = (props) => {
  const auth = useContext(AuthContext);
  const store = useContext(StoreContext);
  const [openError, setOpenError] = useState(false);
  const [markerlocation, setMarkerLocation] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const navigate = useNavigate();

  const updateStoreItems = store.UpdateSchools;
  const ItemTypeTR = "Kurum";
  const ItemEndPointType = "schools";
  const APIEndPoint = "schools";

  const SetLocationFromAddress = async (event) => {
    let location;
    try {
      location = await LocationFromAddress(formState.inputs.address.value);
    } catch (error) {
      setMarkerLocation(null);
      setOpenError(true);
      return;
    }
    setMarkerLocation(location);
  };

  const itemSubmitHandler = async (event) => {
    event.preventDefault();
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/${APIEndPoint}`,
        "POST",
        JSON.stringify({
          name: formState.inputs.name.value,
          type: formState.inputs.type.value,
          useqrcode: formState.inputs.useqrcode.value,
          phonenumber: formState.inputs.phonenumber.value,
          email: formState.inputs.email.value,
          address: formState.inputs.address.value,
          location: markerlocation,
          distance: formState.inputs.distance.value,
          company: auth?.company || undefined,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      updateStoreItems();
      navigate(`/${ItemEndPointType}`);
    } catch (err) {}
  };

  const [formState, inputHandler] = useForm(
    {
      name: { value: "", isValid: false },
      type: { value: "", isValid: false },
      useqrcode: { value: false, isValid: false },
      phonenumber: { value: "", isValid: false },
      email: { value: "", isValid: false },
      address: { value: null, isValid: false },
      distance: { value: null, isValid: true },
    },
    false
  );

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  const mapClickHandler = (event) => {
    const currentLocation = { lat: event.lat, lng: event.lng };
    setMarkerLocation(currentLocation);
  };

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />

      <Snackbar
        open={openError}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Adresten konum bulunamadı.
        </MuiAlert>
      </Snackbar>

      <form className="new-item-form" onSubmit={itemSubmitHandler}>
        {isLoading && <LoadingSpinner asOverlay />}
        <h3>{ItemTypeTR} Ekle</h3>
        <Input
          id="name"
          element="input"
          type="text"
          label="İsim"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="İsim giriniz."
          onInput={inputHandler}
        />

        <CustomSelect
          options={schooltypes}
          id="type"
          onInput={inputHandler}
          label="Kurum Tipi*"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Zorunlu alan"
        ></CustomSelect>

        <CustomSelect
          options={qrcodetypes}
          id="useqrcode"
          onInput={inputHandler}
          label="Servislerde QR Kod okutulacak mı?*"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Zorunlu alan"
        ></CustomSelect>

        <Input
          id="email"
          element="input"
          type="text"
          label="Email"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Email."
          onInput={inputHandler}
        />

        <Input
          id="address"
          element="input"
          type="text"
          label="Adres"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Adres giriniz."
          onInput={inputHandler}
        />

        <Button type="button" danger onClick={SetLocationFromAddress}>
          Adresten Konumu Getir
        </Button>

        {markerlocation && (
          <div className="map-container">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyAkGOMUyxOH98l6qetwEChfJDgzMrBiDbc",
              }}
              defaultCenter={markerlocation}
              defaultZoom={16}
              onClick={mapClickHandler}
            >
              <Marker Icon={RoomIcon} lat={markerlocation.lat} lng={markerlocation.lng} />
            </GoogleMapReact>
            {/* <Map center={GetCoordinates(loadedItem.address)} zoom={16} /> */}
          </div>
        )}

        <Input
          id="phonenumber"
          element="input"
          type="text"
          label="Telefon"
          validators={[VALIDATOR_PHONENUMBER(10)]}
          initialValue={generatePhoneNumber()}
          initialValid={true}
          errorText="Telefon giriniz."
          onInput={inputHandler}
        />

        <Input
          id="distance"
          element="input"
          type="text"
          label="Kuruma ulaşma sayılması için mesafe"
          validators={[VALIDATOR_MAX(5), VALIDATOR_MIN(0.1)]}
          initialValue={0.2}
          initialValid={true}
          onInput={inputHandler}
          errorText="Mesafe 0.1 ve 5 arasında bir sayı olmalıdır. Km'yi ifade eder."
        />

        <Button type="submit" disabled={!formState.isValid}>
          Yeni {ItemTypeTR} Yarat
        </Button>
      </form>
    </React.Fragment>
  );
};

export default NewSchoolItem;
