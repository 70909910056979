import React, { useEffect, useState, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import GoogleMapReact from "google-map-react";
import RoomIcon from "@mui/icons-material/Room";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

// #region Components
import Input from "shared/components/FormElements/Input";
import CustomSelect from "shared/components/FormElements/Select";
import Button from "shared/components/FormElements/Button";
import Card from "shared/components/UIElements/Card";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import Modal from "shared/components/UIElements/Modal";
import Marker from "shared/components/UIElements/Marker";
// #endregion Components

// #region Utils
import { VALIDATOR_REQUIRE, VALIDATOR_PHONENUMBER, VALIDATOR_MAX, VALIDATOR_MIN } from "shared/util/validators";
import { LocationFromAddress } from "shared/util/location";
import { schooltypes, qrcodetypes, yesNoOptions } from "shared/util/types";
// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

const SchoolItem = (props) => {
  const auth = useContext(AuthContext);
  const store = useContext(StoreContext);

  const [itemstate, setItemState] = useState();
  const [loadedItem, setLoadedItem] = useState();
  const [openError, setOpenError] = React.useState(false);
  const [markerlocation, setMarkerLocation] = useState();

  const itemId = props.itemid;
  const updateStoreItems = store.UpdateSchools;

  const ItemTypeTR = "Kurum";
  const ItemType = "school";
  const ItemEndPointType = "schools";

  // #region Standard Interface functions
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const navigate = useNavigate();

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const showDeleteWarningHandler = () => {
    setShowConfirmModal(true);
  };

  const cancelDeleteHandler = () => {
    setShowConfirmModal(false);
  };
  // #endregion Standard Interface functions

  // #region Standard API calls
  const fetchItem = useCallback(async () => {
    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`);
      setLoadedItem(responseData[ItemType]);
      setMarkerLocation(responseData[ItemType].location);
      setItemState(Date.now());
    } catch (err) {}
  }, [sendRequest, itemId]);

  const confirmDeleteHandler = async () => {
    setShowConfirmModal(false);
    try {
      await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`, "DELETE", null, {
        "Content-Type": "application/json",
      });
      setItemState(Date.now());
      updateStoreItems();
      setLoadedItem(null);
      navigate(`/${ItemEndPointType}`);
    } catch (err) {}
  };
  // #endregion Standard API calls

  useEffect(() => {
    fetchItem();
  }, [itemId, fetchItem]);

  const [formState, inputHandler] = useForm(
    {
      name: { value: "", isValid: true },
      type: { value: "", isValid: true },
      useqrcode: { value: "", isValid: true },
      phonenumber: { value: "", isValid: true },
      email: { value: "", isValid: true },
      address: { value: null, isValid: true },
      location: { value: null, isValid: true },
      distance: { value: null, isValid: true },
      usemagneticcard: { value: false, isValid: true },
    },
    true
  );

  // eslint-disable-next-line
  const itemUpdateSubmitHandler = useCallback(async (event) => {
    event.preventDefault();
    let responseData;
    try {
      responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`,
        "PATCH",
        JSON.stringify({
          name: formState.inputs.name.value,
          type: formState.inputs.type.value,
          useqrcode: formState.inputs.useqrcode.value,
          phonenumber: formState.inputs.phonenumber.value,
          email: formState.inputs.email.value,
          address: formState.inputs.address.value,
          location: markerlocation,
          distance: formState.inputs.distance.value,
          usemagneticcard: formState.inputs.usemagneticcard.value,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      setLoadedItem(responseData[ItemType]);
      setItemState(Date.now());
      updateStoreItems();

      navigate(`/${ItemEndPointType}`);
    } catch (err) {}
  });

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!loadedItem && !error) {
    return (
      <div className="center">
        <Card>
          <h2>{ItemTypeTR} bulunamadı.</h2>
        </Card>
      </div>
    );
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  const mapClickHandler = (event) => {
    const currentLocation = { lat: event.lat, lng: event.lng };
    setMarkerLocation(currentLocation);
  };

  const SetLocationFromAddress = async (event) => {
    let location;
    try {
      location = await LocationFromAddress(formState.inputs.address.value);
    } catch (error) {
      setMarkerLocation(null);
      setOpenError(true);
      return;
    }
    setMarkerLocation(location);
  };

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <Modal
        show={showConfirmModal}
        onCancel={cancelDeleteHandler}
        header="Emin misiniz?"
        footerClass="place-item__modal-actions"
        footer={
          <div className="modalbuttonscontainer">
            <Button inverse onClick={cancelDeleteHandler}>
              Hayır
            </Button>
            <Button danger onClick={confirmDeleteHandler}>
              Evet
            </Button>
          </div>
        }
      >
        <p>{ItemTypeTR} silme işlemini onaylıyor musunuz? Bu işlem geri alınamaz.</p>
      </Modal>

      <Snackbar
        open={openError}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Adresten konum bulunamadı.
        </MuiAlert>
      </Snackbar>

      {!isLoading && loadedItem && (
        <form key={itemstate} className="item-form" onSubmit={itemUpdateSubmitHandler}>
          <div className="button-container">
            <Button type="button" danger onClick={props.onClose}>
              &#60; Geri
            </Button>
          </div>
          <Input
            id="name"
            element="input"
            type="text"
            label="İsim"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="İsim giriniz."
            initialValue={loadedItem.name}
            initialValid={true}
            onInput={inputHandler}
          />

          <CustomSelect
            options={schooltypes}
            id="type"
            onInput={inputHandler}
            label="Kurum Tipi*"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Zorunlu alan"
            initialValue={schooltypes.find((option) => option.value.toString() === loadedItem.type.toString())}
          ></CustomSelect>

          <CustomSelect
            options={qrcodetypes}
            id="useqrcode"
            onInput={inputHandler}
            label="Servislerde QR Kod okutulacak mı?*"
            validators={[VALIDATOR_REQUIRE()]}
            initialValue={qrcodetypes.find((option) => option.value.toString() === loadedItem.useqrcode.toString())}
            errorText="Zorunlu alan"
          ></CustomSelect>

          <CustomSelect
            options={yesNoOptions}
            id="usemagneticcard"
            onInput={inputHandler}
            label="Kurum çalışanları uyumlu manyetik kart kullanıyor mu?*"
            validators={[VALIDATOR_REQUIRE()]}
            initialValue={yesNoOptions.find(
              (option) => option.value.toString() === loadedItem.usemagneticcard.toString()
            )}
            errorText="Zorunlu alan"
          ></CustomSelect>

          <Input
            id="email"
            element="input"
            type="text"
            label="Email"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Email giriniz."
            initialValue={loadedItem.email}
            initialValid={true}
            onInput={inputHandler}
          />

          <Input
            id="address"
            element="input"
            type="text"
            label="Adres"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Adres giriniz."
            initialValue={loadedItem.address}
            initialValid={true}
            onInput={inputHandler}
          />

          <Button type="button" danger onClick={SetLocationFromAddress}>
            Adresten Konumu Getir
          </Button>

          {markerlocation && (
            <div className="map-container">
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyAkGOMUyxOH98l6qetwEChfJDgzMrBiDbc",
                }}
                defaultCenter={markerlocation}
                defaultZoom={16}
                onClick={mapClickHandler}
              >
                <Marker Icon={RoomIcon} lat={markerlocation.lat} lng={markerlocation.lng} />
              </GoogleMapReact>
              {/* <Map center={GetCoordinates(loadedItem.address)} zoom={16} /> */}
            </div>
          )}

          <Input
            id="phonenumber"
            element="input"
            type="text"
            label="Telefon"
            validators={[VALIDATOR_PHONENUMBER(10)]}
            //errorText="Telefon giriniz."
            initialValue={loadedItem.phonenumber}
            initialValid={true}
            onInput={inputHandler}
          />

          <Input
            id="distance"
            element="input"
            type="text"
            label="Kuruma ulaşma sayılması için mesafe"
            validators={[VALIDATOR_MAX(5), VALIDATOR_MIN(0.1)]}
            initialValue={loadedItem.distance}
            initialValid={true}
            onInput={inputHandler}
            errorText="Mesafe 0.1 ve 5 arasında bir sayı olmalıdır. Km'yi ifade eder."
          />
          <div className="button-container">
            <Button type="submit" disabled={!formState.isValid}>
              Güncelle
            </Button>

            <Button type="button" danger onClick={showDeleteWarningHandler}>
              SİL
            </Button>
          </div>
        </form>
      )}
    </React.Fragment>
  );
};

export default SchoolItem;
