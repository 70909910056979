import React, { useEffect, useState, useRef, useContext } from "react";
import GoogleMapReact, { fitBounds } from "google-map-react";
import RoomIcon from "@mui/icons-material/Room";
import SchoolIcon from "@mui/icons-material/School";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";

import "./Map.css";

// #region Components

import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";

import Marker from "shared/components/UIElements/Marker";

const Map = ({ stops, type, origin, enablePolyline, isFullScreen }) => {
  const auth = useContext(AuthContext);
  const mapREF = useRef(null);
  const mapsREF = useRef(null);
  const polylinesREF = useRef([]);
  const polyLineLabelsREF = useRef([]);
  const distances = useRef([]);
  const [totalDistance, setTotalDistance] = useState(0);
  const [totalDuration, setTotalDuration] = useState(0);

  const [mapCenter, setMapCenter] = useState({ lat: 36.8969, lng: 30.7133 });
  const [mapZoom, setMapZoom] = useState(14);

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const handleApiLoaded = (map, maps) => {
    mapREF.current = map;
    mapsREF.current = maps;
    drawPolylines();
  };

  const drawPolylines = async () => {
    if (!enablePolyline) {
      return;
    }
    if (!mapREF.current || !mapsREF.current) {
      return;
    }

    for (var k = 0; k < polylinesREF.current.length; k++) {
      polylinesREF.current[k].setMap(null);
    }
    for (var l = 0; l < polyLineLabelsREF.current.length; l++) {
      polyLineLabelsREF.current[l].setMap(null);
    }
    const currentPolylines = [];
    const currentPolylineLabels = [];
    const currentPeople = [];
    let totalDistanceData = 0;
    let totalDurationData = 0;
    for (var i = 0; i < stops.length; i++) {
      // eslint-disable-next-line no-loop-func
      let person = stops[i];
      let nextPersonId = null;
      if (type === "100") {
        if (i < stops.length - 1) {
          nextPersonId = stops[i + 1].id;
        } else {
          nextPersonId = origin.id;
        }
      }
      if (type === "200") {
        if (i == 0) {
          person = { id: origin.id, name: origin.name, lat: origin.location.lat, lng: origin.location.lng };
          nextPersonId = stops[i].id;
        } else {
          person = stops[i - 1];
          nextPersonId = stops[i].id;
        }
      }

      let currentDistance = distances.current.find(
        (distance) => distance.from === person.id && distance.to === nextPersonId
      )?.distance;
      if (!currentDistance) {
        let stopsToAsk = stops.filter((stop) => stop.id === person.id || stop.id === nextPersonId);
        if (i == stops.length - 1 && type === "100") {
          stopsToAsk = stops.filter((stop) => stop.id === person.id);
          stopsToAsk.push(origin);
        }
        if (i == 0 && type === "200") {
          stopsToAsk.push(origin);
          stopsToAsk.push(stops.find((stop) => stop.id === nextPersonId));
        }
        const data = {
          origin,
          stops: stopsToAsk,
          type,
        };

        const response = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/automated/getdistances`,
          "POST",
          JSON.stringify(data),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );
        if (response.distances && response.distances.length === 0) {
          debugger;
        }
        if (response.distances && response.distances.length > 0) {
          currentDistance = response.distances.find(
            (distance) => distance.from === person.id && distance.to === nextPersonId
          ).distance;
          distances.current.push({ from: person.id, to: nextPersonId, distance: currentDistance });
        }
      }

      const currentPolygons =
        currentDistance?.data?.steps?.map((step) => {
          return [
            { lat: step.start_location.lat, lng: step.start_location.lng },
            { lat: step.end_location.lat, lng: step.end_location.lng },
          ];
        }) || [];

      const currentDistanceDurationValue = currentDistance?.data?.duration?.value || 0;
      const currentDistanceDistanceValue = currentDistance?.data?.distance?.value || 0;

      totalDistanceData += currentDistanceDistanceValue;
      totalDurationData += currentDistanceDurationValue;

      for (var j = 0; j < currentPolygons.length; j++) {
        var polyline = new mapsREF.current.Polyline({
          path: currentPolygons[j],
          strokeColor: "#FF0000",
          strokeOpacity: 1,
          strokeWeight: 2,
          fillOpacity: 0,
          icons: [
            {
              icon: { path: mapsREF.current.SymbolPath.FORWARD_CLOSED_ARROW },
              offset: "100%",
              repeat: "30px",
            },
          ],
        });
        currentPolylines.push(polyline);
        polyline.setMap(mapREF.current);
      }
    }

    setTotalDistance(totalDistanceData);
    setTotalDuration(totalDurationData);
    polylinesREF.current = currentPolylines;
  };

  useEffect(() => {
    if (!stops?.length || mapsREF.current === null || mapREF.current === null) {
      return;
    }
    drawPolylines();
  }, [stops, enablePolyline]);

  const getMapBounds = () => {
    const bounds = new mapsREF.current.LatLngBounds();

    stops.forEach((place) => {
      bounds.extend(new mapsREF.current.LatLng(place.location.lat, place.location.lng));
    });
    return bounds;
  };

  //   const bindResizeListener = (bounds) => {
  //     mapsREF.current.event.addDomListenerOnce(mapREF, "idle", () => {
  //       mapsREF.current.event.addDomListener(window, "resize", () => {
  //         mapREF.current.fitBounds(bounds);
  //       });
  //     });
  //   };

  //   if (!stops?.length || !isInitialized) {
  //     return (
  //       <div className="center">
  //         <LoadingSpinner />
  //       </div>
  //     );
  //   }

  return (
    <React.Fragment>
      <div className={isFullScreen ? "shared-service-map-container-big" : "shared-service-map-container"}>
        {enablePolyline && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>
              Mesafe: <b> {(totalDistance / 1000).toFixed(0)} km </b>
            </div>
            <div>
              Süre: <b> {(totalDuration / 60).toFixed(0)} dk </b>
            </div>
          </div>
        )}
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyAkGOMUyxOH98l6qetwEChfJDgzMrBiDbc",
            language: "tr",
            region: "tr",
            libraries: ["drawing"],
          }}
          yesIWantToUseGoogleMapApiInternals //this is important!
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          options={function (maps) {
            return {
              mapTypeControl: true,
              mapTypeId: "roadmap",
              mapTypeControlOptions: {
                mapTypeIds: ["satellite", "roadmap"],
                style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: maps.ControlPosition.TOP_LEFT,
              },
            };
          }}
          //defaultCenter={markerLocationMorning}
          center={mapCenter}
          zoom={mapZoom}
        >
          {origin && (
            <Marker
              Icon={SchoolIcon}
              lat={origin.location.lat}
              lng={origin.location.lng}
              info={origin.name}
              initialShow
            />
          )}

          {stops?.length &&
            stops?.map((stop, index) => {
              return (
                <Marker
                  Icon={RoomIcon}
                  lat={stop?.location?.lat || 1.1}
                  lng={stop?.location?.lng || 1.1}
                  key={stop.id}
                  //   info={`${stop.name} - ${stop.users.map((user) => user.name)}`}
                  info={index + 1 + ". " + stop.name}
                  initialShow
                />
              );
            })}
        </GoogleMapReact>
      </div>
    </React.Fragment>
  );
};

export default Map;
