import React from "react";
import { useTranslation } from "react-i18next";

import Button from "shared/components/FormElements/Button";

const ItemHeader = (props) => {
  const { t } = useTranslation();

  const fullScreenHandler = () => {
    if (props.isFullScreen) {
      props.onHalfScreen();
    } else {
      props.onFullScreen();
    }
  };

  return (
    <div style={{ display: "flex", columnGap: "30px" }}>
      <Button type="button" size="small" danger onClick={props.onClose}>
        &#60; {t("close")}
      </Button>

      <Button type="button" size="small" danger onClick={fullScreenHandler}>
        {!props.isFullScreen ? "Tam Ekran" : "Küçült"}
      </Button>
    </div>
  );
};

export default ItemHeader;
