import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import ServiceSms from "components/shared/ServiceSms";

// #region Components
import Input from "shared/components/FormElements/Input";
import Button from "shared/components/FormElements/Button";
import CustomSelect from "shared/components/FormElements/Select";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import Modal from "shared/components/UIElements/Modal";
import NotFound from "shared/components/UIElements/NotFound";

// #endregion Components

// #region Utils
import { GetStartStopTime } from "shared/util/time";
import { CheckTimeString, formatDate } from "shared/util/time";
import { personelServiceRouteTypes, yesNoOptions } from "shared/util/types";
import { VALIDATOR_REQUIRE, VALIDATOR_NONE } from "shared/util/validators";
// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
import { isArray, set } from "lodash";
import { sleep } from "shared/util/util";

import ItemHeader from "components/shared/ItemHeader";
import Map from "components/shared/Map";
// #endregion Hooks

const PersonelServiceItem = (props) => {
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const {
    schools,
    users,
    schoolOptions,
    UpdatePersonelService,
    DeletePersonelService,
    driverOptions,
    userOptions,
    stops,
  } = useContext(StoreContext);

  const navigate = useNavigate();
  const [loadedItem, setLoadedItem] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [currentStops, setCurrentStops] = useState([]);
  const [currentSmses, setCurrentSmses] = useState([]);
  const [currentUsers, setCurrentUsers] = useState();
  const [itemstate, setItemState] = useState();
  const alertMessageRef = useRef("");
  const [view, setView] = useState(1);
  const [isSmsSending, setIsSmsSending] = useState(false);

  const mapREF = useRef(null);
  const mapsREF = useRef(null);

  const [enableSort, setEnableSort] = useState(false);

  const itemId = props.itemid;
  const ItemType = "personelservice";
  const ItemEndPointType = "personelservices";

  // #region Standard Interface functions
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const showDeleteWarningHandler = () => {
    setShowConfirmModal(true);
  };
  const cancelDeleteHandler = () => {
    setShowConfirmModal(false);
  };

  const updateCurrentStops = async (data, usersdetails) => {
    if (!data) {
      setCurrentStops([]);
      return;
    }

    const nowUsers = users.filter((item) =>
      isArray(data) ? data.includes(item.id) : formState.inputs.users.value.includes(item.id)
    );
    const newStops = [];
    const newUsers = [];

    for (let i = 0; i < nowUsers.length; i++) {
      const user = nowUsers[i];
      if (usersdetails && usersdetails.length > 0) {
        const currentUserDetails = usersdetails.find((item) => item?.user === user.id);
        if (currentUserDetails) {
          user.pickupTime = currentUserDetails.transfertime;
        }
      }
      newStops.push({
        id: user.id,
        name: user.name,
        location: user.location,
        users: [user],
        pickupTime: user.pickupTime || "",
      });
      newUsers.push(user);
    }

    setCurrentUsers(newUsers);

    let sortedNewStops = [];

    if (usersdetails && usersdetails.length > 0) {
      for (let i = 0; i < usersdetails.length; i++) {
        const currentUserDetails = usersdetails[i];
        const currentUser = nowUsers.find((item) => item.id === currentUserDetails.user);
        if (!currentUser) {
          continue;
        }
        if (!sortedNewStops.find((item) => item.id === currentUser.id)) {
          sortedNewStops.push({
            id: currentUser.id,
            name: currentUser.name,
            location: currentUser.location,
            users: [currentUser],
            pickupTime: currentUserDetails.transfertime,
          });
        }
      }
      setCurrentStops(sortedNewStops);
    } else {
      const stopsToBeAddredToCurrenctStops = newStops.filter(
        (item) => !currentStops.find((stop) => stop.id === item.id)
      );

      if (stopsToBeAddredToCurrenctStops.length > 0) {
        setCurrentStops([...currentStops, ...stopsToBeAddredToCurrenctStops]);
      } else {
        setCurrentStops([...currentStops.filter((item) => newStops.find((stop) => stop.id === item.id))]);
      }
      // setCurrentStops(newStops);
    }
  };

  useEffect(() => {
    if (!currentStops || currentStops.length === 0 || mapsREF.current === null || mapREF.current === null) {
      return;
    }
    const myBounds = getMapBounds();
    mapREF.current.fitBounds(myBounds);
  }, [currentStops, mapsREF.current, mapREF.current]);

  const fetchItem = useCallback(async () => {
    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`);
      setLoadedItem(responseData[ItemType]);
      updateCurrentStops(responseData[ItemType].users, responseData[ItemType]?.usersdetails);
      setCurrentUsers(users.filter((item) => responseData[ItemType].users.includes(item.id)));

      setItemState(Date.now());
    } catch (err) {
      if (err?.code === 401) {
        auth.logout();
        navigate("/login");
      }
    }
  }, [itemId]);

  const GotoView = (view) => {
    setView(view);
  };

  const confirmDeleteHandler = async () => {
    setShowConfirmModal(false);
    try {
      const deleteResult = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`,
        "DELETE",
        null,
        {
          "Content-Type": "application/json",
        }
      );

      if (deleteResult?.message === "Deleted") {
        DeletePersonelService(itemId);
        props.onClose();
      }
    } catch (err) {}
  };
  // #endregion Standard API calls

  useEffect(() => {
    fetchItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  const [formState, inputHandler] = useForm(
    {
      school: { value: null, isValid: false },
      type: { value: null, isValid: false },
      name: { value: null, isValid: false },
      drivers: { value: [], isValid: false },
      plate: { value: null, isValid: true },
      starttime: { value: null, isValid: false },
      stoptime: { value: null, isValid: false },
      users: { value: [], isValid: false },
      useUsersDetails: { value: false, isValid: true },
      usersdetails: { value: [], isValid: true },
    },
    true
  );

  const itemUpdateSubmitHandler = async (event) => {
    event.preventDefault();

    if (!CheckTimeString(formState.inputs.starttime.value) || !CheckTimeString(formState.inputs.stoptime.value)) {
      alertMessageRef.current = "Saat bilgisini **:** formatında giriniz. Örnek: 07:00 ya da 18:35";
      setShowAlert(true);
      return;
    }

    let usersdetails = loadedItem.usersdetails ? loadedItem.usersdetails : [];
    let usersDetailsToBeSent = [];
    if (formState.inputs.useUsersDetails.value && formState.inputs.type.value === "100") {
      for (let i = 0; i < currentStops.length; i++) {
        const person = currentStops[i];
        const pickupTime = document.getElementById(person.id + "-pickupTime").value;
        if (!CheckTimeString(pickupTime)) {
          alertMessageRef.current = `${person.name} için saat bilgisini **:** formatında giriniz. Örnek: 07:00 ya da 18:35`;
          setShowAlert(true);
          return;
        }
        person.pickupTime = pickupTime;
      }

      for (let i = 0; i < currentStops.length; i++) {
        const person = currentStops[i];
        if (usersdetails.find((item) => item.user === person.id)) {
          const currentUsersDetails = usersdetails.find((item) => item.user === person.id);
          currentUsersDetails.transfertime = person.pickupTime;
        } else {
          usersdetails.push({ user: person.id, transfertime: person.pickupTime });
        }
        usersDetailsToBeSent.push({ user: person.id, transfertime: person.pickupTime });
      }
    }

    try {
      const infoToBeSent = {
        school: formState.inputs.school.value,
        type: formState.inputs.type.value,
        name: formState.inputs.name.value,
        drivers: formState.inputs.drivers.value,
        plate: formState.inputs.plate.value,
        starttime: formState.inputs.starttime.value,
        stoptime: formState.inputs.stoptime.value,
        isshift: false,
        users: formState.inputs.users.value,
        useUsersDetails: formState.inputs.useUsersDetails.value,
        usersdetails: usersDetailsToBeSent,
      };

      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`,
        "PATCH",
        JSON.stringify(infoToBeSent),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      if (responseData?.[ItemType]) {
        UpdatePersonelService(responseData[ItemType]);
        setLoadedItem({ ...responseData[ItemType] });
        updateCurrentStops(responseData[ItemType].users, responseData[ItemType]?.usersdetails);
        setCurrentUsers(users.filter((item) => responseData[ItemType].users.includes(item.id)));
        setItemState(Date.now());
      }
    } catch (err) {}
  };

  // Return map bounds based on list of places
  const getMapBounds = () => {
    const bounds = new mapsREF.current.LatLngBounds();

    currentStops.forEach((place) => {
      bounds.extend(new mapsREF.current.LatLng(place.location.lat, place.location.lng));
    });
    return bounds;
  };

  const personPickupTimeChangeHandler = (event, personId) => {
    const person = currentStops.find((person) => person.id === personId);
    person.pickupTime = event.target.value;
    setCurrentStops([...currentStops]);
  };

  const personUpHandler = (personId) => {
    const currentPerson = currentStops.find((person) => person.id === personId);
    const pos = currentStops.map((person) => person.id).indexOf(personId);
    let newPeople = [];
    for (let i = 0; i < currentStops.length; i++) {
      if (i === pos && i !== 0) {
        const prev = newPeople.pop();
        newPeople.push(currentStops[i]);
        newPeople.push(prev);
        continue;
      } else {
        newPeople.push(currentStops[i]);
      }
    }
    setCurrentStops([...newPeople]);
  };

  const personDownHandler = (personId) => {
    const pos = currentStops.findIndex((person) => person.id === personId);

    if (pos < currentStops.length - 1) {
      let temp = currentStops[pos];
      currentStops[pos] = currentStops[pos + 1];
      currentStops[pos + 1] = temp;
      setCurrentStops([...currentStops]);
    }
  };

  if (isLoading || isSmsSending) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!loadedItem && !error) {
    return <NotFound item={ItemType}></NotFound>;
  }

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowAlert(false);
  };

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <Modal
        show={showConfirmModal}
        onCancel={cancelDeleteHandler}
        header={t("areYouSure")}
        footerClass="place-item__modal-actions"
        footer={
          <div className="modalbuttonscontainer">
            <Button inverse onClick={cancelDeleteHandler}>
              {t("no")}
            </Button>
            <Button danger onClick={confirmDeleteHandler}>
              {t("yes")}
            </Button>
          </div>
        }
      >
        <p>{t(`standart${ItemType}.deleteItemWarning`)}</p>
      </Modal>

      <Snackbar
        open={showAlert}
        autoHideDuration={2000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert onClose={handleAlertClose} severity="error" sx={{ width: "100%" }}>
          {alertMessageRef.current}
        </MuiAlert>
      </Snackbar>

      {!isLoading && loadedItem && (
        <form key={itemstate} className="item-form" onSubmit={itemUpdateSubmitHandler}>
          <div className="button-container">
            <ItemHeader
              onClose={props.onClose}
              onFullScreen={props.onFullScreen}
              onHalfScreen={props.onHalfScreen}
              isFullScreen={props.isFullScreen}
            />

            {view === 1 && (
              <Button type="button" danger onClick={() => GotoView(2)}>
                SMS Yönetimi &#62;
              </Button>
            )}

            {view === 2 && (
              <Button type="button" danger onClick={() => GotoView(1)}>
                Servis Yönetimi &#62;
              </Button>
            )}
          </div>

          {view === 1 && (
            <div>
              <CustomSelect
                options={schoolOptions}
                id="school"
                onInput={inputHandler}
                label={t("client")}
                initialValue={schoolOptions.find((option) => option.value === loadedItem.school.toString())}
                initialValid={true}
              ></CustomSelect>

              <CustomSelect
                options={personelServiceRouteTypes}
                id="type"
                onInput={inputHandler}
                initialValue={personelServiceRouteTypes.find((option) => option.value === loadedItem.type)}
                initialValid={true}
                label="Rota Tipi"
              ></CustomSelect>

              <Input
                id="name"
                element="input"
                type="text"
                label={t("name")}
                validators={[VALIDATOR_REQUIRE()]}
                errorText={t("requireField", { item: t("name") })}
                initialValue={loadedItem.name}
                initialValid={true}
                onInput={inputHandler}
              />

              <CustomSelect
                options={driverOptions}
                id="drivers"
                isMulti={true}
                onInput={inputHandler}
                label="Rotaya Yetkisi Olan Sürücüler"
                initialValue={driverOptions.filter((option) => loadedItem?.drivers?.includes(option.value))}
                initialValid={true}
              ></CustomSelect>

              <Input
                id="plate"
                element="input"
                type="text"
                label="Güzergaha tanımlı aracın plakası"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Zorunlu alan"
                initialValue={loadedItem.plate}
                initialValid={true}
                onInput={inputHandler}
              />

              <Input
                id="starttime"
                element="input"
                type="text"
                label="Başlama Saati"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="**:** formatında giriniz. Örnek: 07:00 ya da 18:35"
                initialValue={GetStartStopTime(loadedItem.starttime)}
                initialValid={true}
                onInput={inputHandler}
              />
              <Input
                id="stoptime"
                element="input"
                type="text"
                label="Bitiş Saati"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="**:** formatında giriniz. Örnek: 07:00 ya da 18:35"
                initialValue={GetStartStopTime(loadedItem.stoptime)}
                initialValid={true}
                onInput={inputHandler}
              />

              <CustomSelect
                options={yesNoOptions}
                id="useUsersDetails"
                onInput={inputHandler}
                initialValue={yesNoOptions.find((option) => option.value === loadedItem?.useUsersDetails)}
                initialValid={true}
                label="Sürücüye ve yolculara biniş/iniş saatini göster"
              ></CustomSelect>

              <CustomSelect
                options={userOptions}
                id="users"
                isMulti={true}
                onInput={inputHandler}
                label="Servisteki Kullanıcılar"
                initialValue={userOptions.filter((option) =>
                  currentUsers?.map((user) => user?.id)?.includes(option.value)
                )}
                initialValid={true}
                fireChange={(val) => updateCurrentStops(val)}
              ></CustomSelect>

              <p>
                <input
                  type="checkbox"
                  id="enableSort"
                  onChange={(e) => {
                    setEnableSort(e.target.checked);
                    if (e.target.checked) {
                      props.onFullScreen();
                    }
                  }}
                />
                <label htmlFor="enableSort">
                  <span />
                  Yolcuların {formState.inputs.type.value === "100" ? "biniş" : "iniş"} sırasını ve saatini girmek
                  istiyorum.
                </label>
              </p>

              {currentStops.length > 0 && (
                <React.Fragment>
                  <div style={{ display: "flex", flexDirection: "row", columnGap: "10px" }}>
                    {enableSort && (
                      <div style={{ width: "400px" }}>
                        {currentStops.map((person, index) => (
                          <div
                            style={{
                              display: "flex",
                              width: props.isFullScreen ? "100%" : "100%",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                            key={"calculatedUsers" + index}
                          >
                            <div style={{ display: "flex", width: "100%", flexDirection: "row", alignItems: "center" }}>
                              <div style={{ display: "flex", flexDirection: "column" }}>
                                <button
                                  style={{
                                    alignSelf: "flex-start",
                                    backgroundColor: "black",
                                    padding: "5px",
                                    marginBottom: "5px",
                                  }}
                                  type="button"
                                  onClick={() => personUpHandler(person.id)}
                                >
                                  <div className="arrow-up"></div>
                                </button>
                                <button
                                  style={{ alignSelf: "flex-start", backgroundColor: "black", padding: "5px" }}
                                  type="button"
                                  onClick={() => personDownHandler(person.id)}
                                >
                                  <div className="arrow-down"></div>
                                </button>
                              </div>

                              <div
                                style={{
                                  backgroundColor: "#bbb",
                                  padding: "10px",
                                  margin: "5px",
                                  width: "100%",
                                  borderRadius: "5px",
                                }}
                              >
                                {index + 1 + ". " + person.name}
                              </div>

                              {formState.inputs.type.value.toString() === "100" && (
                                <input
                                  style={{
                                    height: "30px",
                                    width: "20%",
                                    borderRadius: "1px",
                                    paddingLeft: "5px",
                                    paddingRight: "5px",
                                    marginRight: "5px",
                                    outlineColor: "black",
                                    borderColor: "black",
                                  }}
                                  type="text"
                                  key={person.id + "-pickupTime"}
                                  id={person.id + "-pickupTime"}
                                  onInput={(e) => personPickupTimeChangeHandler(e, person.id)}
                                  value={person?.pickupTime}
                                />
                              )}
                            </div>
                            {person.polylineLabel && (
                              <div style={{ marginBottom: "10px" }}>
                                {" "}
                                {">> "}
                                {person?.polylineLabel} {" >>"}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    )}

                    <Map
                      stops={currentStops}
                      origin={schools.find((school) => formState.inputs.school.value === school.id)}
                      type={formState.inputs.type.value}
                      enablePolyline={enableSort}
                      isFullScreen={props.isFullScreen}
                    />
                  </div>
                </React.Fragment>
              )}

              <div className="button-container">
                <Button type="submit" disabled={!formState.isValid}>
                  {t("update")}
                </Button>

                <Button type="button" danger onClick={showDeleteWarningHandler}>
                  {t("delete")}
                </Button>
              </div>
            </div>
          )}

          {view === 2 && <ServiceSms users={currentUsers} currentSmses={currentSmses} serviceId={itemId} />}
        </form>
      )}
    </React.Fragment>
  );
};

export default PersonelServiceItem;
