import React, { useCallback, useState, useContext } from "react";
import { NavLink } from "react-router-dom";

import VehiclesList from "../components/vehicle/VehiclesList";
import VehicleItem from "../components/vehicle/VehicleItem";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "shared/hooks/http-hook";
import { StoreContext } from "shared/context/store-context";
import "./Admin.css";

const Vehicles = () => {
  const store = useContext(StoreContext);
  const { isLoading, error, clearError } = useHttpClient();
  const [selectedItemId, setSelectedItemId] = useState();

  const pageItems = store.vehicles;
  const newItemLink = "/new/vehicle";
  const ItemTypeTR = "Araç";

  const ItemSelectHandler = useCallback((itemid) => {
    setSelectedItemId(itemid);
  }, []);

  const ItemUpdateHandler = useCallback((itemid) => {
    setSelectedItemId(itemid);
  }, []);

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}

      <div className="adminNavigationHeader">
        <NavLink to={newItemLink} exact>
          Yeni {ItemTypeTR}
        </NavLink>
      </div>
      <div className="adminpageContainer">
        {!isLoading && pageItems && (
          <div className="adminListContainer">
            <VehiclesList items={pageItems} onItemSelect={ItemSelectHandler} />
          </div>
        )}
        {selectedItemId && (
          <div className="adminUpdateContainer">
            <VehicleItem itemid={selectedItemId} onItemUpdate={ItemUpdateHandler}></VehicleItem>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Vehicles;
