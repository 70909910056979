import React, { useContext, useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import GoogleMapReact from "google-map-react";
import RoomIcon from "@mui/icons-material/Room";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

// #region Components
import Input from "shared/components/FormElements/Input";
import Button from "shared/components/FormElements/Button";
import CustomSelect from "shared/components/FormElements/Select";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import Marker from "shared/components/UIElements/Marker";
// #endregion Components

// #region Utils
import { generatePhoneNumber } from "shared/util/util";
import { LocationFromAddress, AddressFromLocation } from "shared/util/location";
import { VALIDATOR_REQUIRE, VALIDATOR_PHONENUMBER, VALIDATOR_NONE } from "shared/util/validators";
// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
import { yesNoOptions } from "shared/util/types";
// #endregion Hooks

const NewUserItem = (props) => {
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const { schoolOptions, InsertUser, stopOptions, schools } = useContext(StoreContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [itemGenerated, setItemGenerated] = useState(false);
  const [showOptionalField, setShowOptionalField] = useState(false);

  const [markerLocation, setMarkerLocation] = useState();
  const [mapCenter, setMapCenter] = useState();
  const [userAddress, setUserAddress] = useState();
  const [openError, setOpenError] = useState(false);

  const refGeneratedUserId = useRef();
  const ItemType = "user";
  const APIEndPoint = "users/signup";

  let usertypestoshow = props.usertypes;

  const itemSubmitHandler = async (event) => {
    event.preventDefault();
    try {
      const infoToBeSent = {
        name: formState.inputs.name.value,
        phonenumber: formState.inputs.phonenumber.value,
        cardId: formStateOptional.inputs.cardId.value,
        getonstop: formStateOptional.inputs.getonstop.value,
        getoffstop: formStateOptional.inputs.getoffstop.value,
        approachtogetonnotification: formStateOptional.inputs.approachtogetonnotification.value,
        shiftchangenotification: formStateOptional.inputs.shiftchangenotification.value,
        isshift: formStateOptional.inputs.isshift.value,
        usertype: formState.inputs.usertype.value,
        email: formState.inputs.email.value,
        address: formState.inputs.address.value,
        identity: formState.inputs.identity.value,
        school: formState.inputs.school.value,
      };

      if (markerLocation) {
        infoToBeSent.location = { lat: markerLocation?.lat || 1.1, lng: markerLocation?.lng || 1.1 };
      }

      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/${APIEndPoint}`,
        "POST",
        JSON.stringify(infoToBeSent),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      if (responseData[ItemType]) {
        InsertUser(responseData[ItemType]);
        refGeneratedUserId.current = responseData[ItemType].id;
        setItemGenerated(true);
      }
    } catch (err) {}
  };

  const [formState, inputHandler] = useForm(
    {
      name: { value: "", isValid: false },
      phonenumber: { value: "", isValid: false },
      usertype: { value: null, isValid: false },
      email: { value: null, isValid: false },
      address: { value: null, isValid: false },
      identity: { value: "", isValid: false },
      school: { value: null, isValid: false },
    },
    false
  );

  const [formStateOptional, inputHandlerOptional, setFormDataOptional] = useForm(
    {
      isshift: { value: null, isValid: true },
      cardId: { value: "", isValid: true },
      getonstop: { value: null, isValid: true },
      getoffstop: { value: null, isValid: true },
      approachtogetonnotification: { value: null, isValid: true },
      shiftchangenotification: { value: null, isValid: true },
    },
    true
  );

  useEffect(() => {
    const currentSchool = schools.find((school) => school.id.toString() === formState.inputs.school?.value?.toString());

    if (currentSchool?.usemagneticcard) {
      setFormDataOptional(
        {
          cardId: { value: null, isValid: true },
          isshift: { value: null, isValid: true },
          getonstop: { value: null, isValid: true },
          getoffstop: { value: null, isValid: true },
          approachtogetonnotification: { value: null, isValid: true },
          shiftchangenotification: { value: null, isValid: true },
        },
        true
      );
      setShowOptionalField(true);
    } else {
      setShowOptionalField(false);
      setFormDataOptional(
        {
          cardId: { value: null, isValid: true },
          isshift: { value: null, isValid: true },
          getonstop: { value: null, isValid: true },
          getoffstop: { value: null, isValid: true },
          approachtogetonnotification: { value: null, isValid: true },
          shiftchangenotification: { value: null, isValid: true },
        },
        true
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.inputs.school?.value]);

  const goToItem = () => {
    props.gotoItem(refGeneratedUserId.current);
  };

  const close = () => {
    setItemGenerated(false);
    props.onClose();
  };

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  const mapClickHandler = async (event) => {
    const currentLocation = { lat: event.lat, lng: event.lng };
    let address = null;
    try {
      address = await AddressFromLocation(currentLocation);
    } catch (error) {
      setOpenError(true);
    }
    if (address) {
      setMarkerLocation(currentLocation);
      setUserAddress(address);
      inputHandler("address", address, true);
    } else {
      setOpenError(true);
      setUserAddress("-");
      inputHandler("address", "-", false);
    }
  };

  const SetLocationFromAddress = async () => {
    let location;
    try {
      location = await LocationFromAddress(formState.inputs.address.value);
    } catch (error) {
      setMarkerLocation(null);
      setOpenError(true);
      return;
    }
    setMarkerLocation(location);
    setMapCenter(location);
  };

  if (itemGenerated) {
    return (
      <div className="center generate-success">
        <h4>{t(`${ItemType}.itemAddedSuccessfully`)}</h4>
        <Button onClick={goToItem}> {t(`${ItemType}.goToNewItem`)}</Button>{" "}
        <Button danger onClick={() => setItemGenerated(false)}>
          {t(`${ItemType}.createNewItem`)}
        </Button>{" "}
        <Button danger onClick={close}>
          {t("close")}
        </Button>{" "}
      </div>
    );
  }

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />

      <Snackbar
        open={openError}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Adres-Konum eşleşmesi bulunamadı.
        </MuiAlert>
      </Snackbar>

      <form className="item-form" onSubmit={itemSubmitHandler}>
        {isLoading && <LoadingSpinner asOverlay />}
        <div className="new-button-container">
          <h3 style={{ margin: 0 }}>{t(`${ItemType}.createNewItem`)}</h3>
          <Button type="button" size="small" danger onClick={props.onClose}>
            &#60; {t("close")}
          </Button>
        </div>

        <CustomSelect options={schoolOptions} id="school" onInput={inputHandler} label={t("client")}></CustomSelect>
        <Input
          id="name"
          element="input"
          type="text"
          label={t("name")}
          validators={[VALIDATOR_REQUIRE()]}
          errorText={t("requireField", { item: t("name") })}
          onInput={inputHandler}
        />
        <Input
          id="phonenumber"
          element="input"
          type="text"
          label={t("phone")}
          validators={[VALIDATOR_PHONENUMBER(10)]}
          initialValue={generatePhoneNumber()}
          initialValid={true}
          errorText={t("requireField", { item: t("phone") })}
          onInput={inputHandler}
        />

        <CustomSelect
          options={usertypestoshow}
          id="usertype"
          onInput={inputHandler}
          label={t("userType")}
        ></CustomSelect>
        {showOptionalField && (
          <div>
            <CustomSelect
              options={yesNoOptions}
              id="isshift"
              onInput={inputHandlerOptional}
              label={t("isshift")}
            ></CustomSelect>

            <CustomSelect
              options={stopOptions}
              id="getonstop"
              onInput={inputHandlerOptional}
              label={t("getonstop")}
            ></CustomSelect>

            <CustomSelect
              options={stopOptions}
              id="getoffstop"
              onInput={inputHandlerOptional}
              label={t("getoffstop")}
            ></CustomSelect>

            <Input
              id="cardId"
              element="input"
              type="text"
              label={t("cardId")}
              validators={[VALIDATOR_NONE()]}
              //initialValue={Math.floor(Math.random() * 999999 + 1)}
              initialValue=""
              initialValid={true}
              onInput={inputHandlerOptional}
            />

            <CustomSelect
              options={yesNoOptions}
              id="approachtogetonnotification"
              onInput={inputHandlerOptional}
              label={t("approachtogetonnotification")}
            ></CustomSelect>

            <CustomSelect
              options={yesNoOptions}
              id="shiftchangenotification"
              onInput={inputHandlerOptional}
              label={t("shiftchangenotification")}
            ></CustomSelect>
          </div>
        )}

        <Input
          id="identity"
          element="input"
          type="text"
          label={t("identityNumber")}
          validators={[VALIDATOR_NONE()]}
          errorText={t("requireField", { item: t("identityNumber") })}
          initialValid={true}
          onInput={inputHandler}
        />

        <Input
          id="email"
          element="input"
          type="text"
          label={t("email")}
          validators={[VALIDATOR_REQUIRE()]}
          errorText={t("requireField", { item: t("email") })}
          onInput={inputHandler}
        />

        <Input
          key={userAddress}
          id="address"
          element="input"
          type="text"
          label={t("address")}
          validators={[VALIDATOR_REQUIRE()]}
          initialValue={userAddress}
          errorText={t("requireField", { item: t("address") })}
          onInput={inputHandler}
        />

        <Button type="button" danger onClick={SetLocationFromAddress.bind(this)}>
          Adresten Konumu Getir
        </Button>

        {markerLocation && (
          <div className="map-container">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyAkGOMUyxOH98l6qetwEChfJDgzMrBiDbc",
              }}
              options={function (maps) {
                return {
                  mapTypeControl: true,
                  mapTypeId: "roadmap",
                  mapTypeControlOptions: {
                    mapTypeIds: ["satellite", "roadmap"],
                    style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                    position: maps.ControlPosition.TOP_LEFT,
                  },
                };
              }}
              center={mapCenter}
              defaultZoom={16}
              onClick={mapClickHandler.bind(this)}
            >
              <Marker Icon={RoomIcon} lat={markerLocation.lat} lng={markerLocation.lng} />
            </GoogleMapReact>
          </div>
        )}
        <div className="button-container">
          <Button type="submit" disabled={!formState.isValid}>
            {t(`${ItemType}.createNewItem`)}
          </Button>
        </div>
      </form>
    </React.Fragment>
  );
};

export default NewUserItem;
